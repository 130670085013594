<template>
    <div class="pc-container">
        <div class="trading-main">
            <tradingAside />
            <div class="trading-content">
                <tradingTop />
                <tradingView />
                <tradingFormWrap />
            </div>
            <tradingRight />
        </div>
        <div class="trading-bottom">
            <tradingOrders />
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            tradingAside: () => import('../trading/aside.vue'),
            tradingTop: () => import('../trading/top.vue'),
            tradingRight: () => import('../trading/right.vue'),
            tradingView: () => import("../trading/chart"),
            tradingFormWrap: () => import('../trading/FormWrap3.vue'),
            tradingOrders: () => import('../trading/orders3.vue')
        },
        data() {
            return {
                
            }
        },
        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        background-color: #f6f6f6;
        
        .trading-main {
            display: flex;
            width: 100%;
            padding: 5px 0;
            
            .trading-content {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0 5px;
            }
        }
        
        .trading-bottom {
            width: 100%;
        }
    }
</style>